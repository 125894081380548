import "./src/scss/style.scss";

export const onRouteUpdate = ({ location, prevLocation }) => {
  collapseWatch();
};
function collapseWatch() {
  // Handler that uses various data-* attributes to trigger
  // specific actions, mimicing bootstraps attributes
  const triggers = Array.from(
    document.querySelectorAll('[data-toggle="collapse"]')
  );

  window.addEventListener(
    "click",
    (ev) => {
      const elm = ev.target;
      if (triggers.includes(elm)) {
        const selector = elm.getAttribute("data-target");
        collapse(selector, "toggle");
      } else if (window.location.pathname === elm.getAttribute("href")) {
        collapse('.navbarSupportedContent', "toggle");
      }
    },
    false
  );
  const fnmap = {
    toggle: "toggle",
    show: "add",
    hide: "remove",
  };

  const collapse = (selector, cmd) => {
    const targets = Array.from(document.querySelectorAll(selector));
    targets.forEach((target) => {
      target.classList[fnmap[cmd]]("show");
    });
  };
}
